import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { darken } from 'polished';

import {
  clear,
  getCart,
  getSelectedPaymentMethod,
  saveCart,
  saveParsedProducts,
  saveSelectedPaymentMethod,
} from '../../utils/cart';
import SEO from '../../components/SEO';
import Container from '../../components/Container';
import { MainLayout } from '../../components/Layout';
import { LightGreenButton, ListProduct, OrderDetail, PaymentMethods, TermAndPolicy } from '../../components/Pricing';
import * as paymentService from '../../apis/payment';
import PricingContext, { PricingEnum } from '../../utils/PricingContext';
import appMapper from '../../content/pricing/app-mapper';
import ErrorMessage from '../../components/ErrorMessage';
import { SlideUpDownAnimated } from '../../utils/animation';

function transformProduct(data) {
  return data.reduce((products, currentProduct, index) => {
    const { name: productName, appUrl } = appMapper[currentProduct.app];
    const testCategories = currentProduct.productsByTestCategory.map(({ testCategoryDTO, products: prices }) => {
      const testCategory = { categoryName: testCategoryDTO.code };

      testCategory.prices = prices.map(({ name, priceInVND, id }) => {
        testCategory.id = id;
        return {
          id,
          description: name,
          price: priceInVND,
          currency: '$',
        };
      });

      return testCategory;
    });

    products.push({ productName, testCategories, id: index, appUrl });
    return products;
  }, []);
}

function transformCart(data) {
  const { orderProducts, subTotalInVND, discountReason, totalInVND, discount: discountAmount } = data;

  const cart = {
    subTotal: subTotalInVND,
    total: totalInVND,
    discount: {
      amount: discountAmount,
      reason: discountReason,
    },
  };

  cart.products = orderProducts.map(({ finalPriceInVND, giveAwayProducts, id, name, app }) => {
    const product = {
      price: finalPriceInVND,
      id,
      name,
      appName: appMapper[app].name,
    };

    product.ga = giveAwayProducts.map(gaProduct => ({
      id: gaProduct.id,
      appName: appMapper[gaProduct.app].name,
      name: gaProduct.name,
      price: 0,
    }));

    return product;
  });

  return cart;
}

function reducer(state, action) {
  switch (action.type) {
    case PricingEnum.SELECT_PRODUCTS:
      if (state.selectedProducts[action.payload.productName] === action.payload.id) {
        return { ...state, selectedProducts: { ...state.selectedProducts, [action.payload.productName]: null } };
      }
      return {
        ...state,
        selectedProducts: { ...state.selectedProducts, [action.payload.productName]: action.payload.id },
      };

    case PricingEnum.CART_CHANGED:
      return { ...state, cart: action.payload.cart };

    default:
      return {
        ...state,
        ...action.payload,
      };
  }
}

function Pricing({ location }) {
  const initialState = {
    /**
     * Contain parsed products fetch from server
     */
    products: [],

    /**
     * Contain selected product by user clicking on Radio buttons
     */
    selectedProducts: {},

    /**
     * parsed products chosen by user, ready for push to backend api
     */
    cart: { products: [] },
    loading: {
      products: false,
    },
    error: {
      products: null,
      cart: null,
    },
    /**
     * show payment method modal or not
     */
    showPaymentMethodChooser: false,

    /**
     * payment fetched from backend api
     */
    paymentMethods: [],

    /**
     * store user selected payment method
     */
    selectedPaymentMethod: null,

    /**
     * Term and policy modal
     */
    showTermAndPolicyModal: false,

    agreedTermAndPolicy: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const hasProductInCart = state.cart.products.length > 0;

  /**
   * Be ready for api call
   * @returns {{quantity: number, productId: *}[]}
   */
  function parseProducts() {
    return Object.keys(state.selectedProducts)
      .map(key => ({ quantity: 1, productId: state.selectedProducts[key] }))
      .filter(product => product.productId !== null);
  }

  /**
   * ComponentDidMount
   * */
  useEffect(() => {
    if (location && location.state && location.state.afterLogin)
      dispatch({
        payload: {
          showTermAndPolicyModal: true,
          agreedTermAndPolicy: true,
          selectedPaymentMethod: getSelectedPaymentMethod(),
        },
      });

    /**
     * Check cart is in the storage or not. If it is, restore cart in ui
     * flow: user click on products -> login -> restore cart
     */
    const cart = getCart();
    if (cart) {
      Object.keys(cart).map(productName =>
        dispatch({
          type: PricingEnum.SELECT_PRODUCTS,
          payload: {
            productName,
            id: cart[productName],
          },
        })
      );

      clear();
    }
  }, []);

  /**
   * fetching products
   */
  useEffect(() => {
    dispatch({ payload: { loading: { ...state.loading, products: true } } });
    paymentService
      .getProducts()
      .then(({ data: products }) => {
        dispatch({ payload: { products: transformProduct(products), loading: { ...state.loading, products: false } } });
      })
      .catch(() => {
        dispatch({
          payload: { error: { ...state.error, products: 'Whoops!, something went wrong! Can not fetch products' } },
        });
      });
  }, []);

  /**
   * Every time check/uncheck a radio button -> call api to get order detail
   */
  useEffect(() => {
    const payload = parseProducts();

    if (payload.length) {
      dispatch({
        payload: { error: { ...state.error, cart: null } },
      });

      paymentService
        .checkout({ products: payload })
        .then(res => {
          dispatch({ type: PricingEnum.CART_CHANGED, payload: { cart: transformCart(res.data) } });
        })
        .catch(() => {
          dispatch({
            payload: { error: { ...state.error, cart: 'Whoops!, something went wrong! Can not fetch order detail.' } },
          });
        });
    } else {
      dispatch({
        payload: {
          cart: {
            products: [],
          },
        },
      });
    }
  }, [state.selectedProducts]);

  return (
    <MainLayout>
      <SEO title="Pricing" keywords={[`dolonline`, `dolenglish`, `pricing`, 'ielts', 'gmat', 'gre']} />
      <PricingContext.Provider value={{ dispatch, state }}>
        <Container bgc="#f9f9f9">
          {state.error.product ? (
            <ErrorMessage>{state.error.product}</ErrorMessage>
          ) : (
            <ListProduct products={state.products} />
          )}
        </Container>

        {hasProductInCart && (
          <Container>
            {state.error.cart ? (
              <ErrorMessage>{state.error.cart}</ErrorMessage>
            ) : (
              <SlideUpDownAnimated hide={!hasProductInCart}>
                <OrderDetailWrapper>
                  <OrderDetail cart={state.cart} />
                </OrderDetailWrapper>
              </SlideUpDownAnimated>
            )}

            <ActionContainer>
              <PurchaseBtn
                onClick={() => {
                  saveCart(state.selectedProducts);
                  paymentService
                    .getPaymentMethods()
                    .then(({ data: paymentMethods }) => {
                      dispatch({
                        payload: { paymentMethods, showPaymentMethodChooser: true },
                      });
                    })
                    // eslint-disable-next-line no-console
                    .catch(err => console.log(err));
                }}
              >
                Choose payment method
              </PurchaseBtn>
              <RedButton
                onClick={() => {
                  saveCart(state.selectedProducts);
                  navigate('/pricing/order-detail/', {
                    state: {
                      error: state.error,
                      products: state.products,
                      cart: state.cart,
                      parsedProducts: parseProducts(),
                    },
                  });
                }}
              >
                check your order
              </RedButton>
            </ActionContainer>
          </Container>
        )}

        <Modal
          showCloseIcon={false}
          center
          open={state.showPaymentMethodChooser}
          onClose={() => {
            dispatch({
              payload: {
                showPaymentMethodChooser: false,
              },
            });
          }}
        >
          <PaymentMethods
            selectedPaymentMethod={state.selectedPaymentMethod}
            paymentMethods={state.paymentMethods}
            onSelectMethod={pm => {
              dispatch({
                payload: {
                  selectedPaymentMethod: pm,
                },
              });
            }}
            onContinue={() => {
              dispatch({
                payload: {
                  showPaymentMethodChooser: false,
                  showTermAndPolicyModal: true,
                },
              });
            }}
          />
        </Modal>

        <Modal
          showCloseIcon={false}
          center
          open={state.showTermAndPolicyModal}
          onClose={() => {
            dispatch({
              payload: {
                showTermAndPolicyModal: false,
              },
            });
          }}
        >
          <TermAndPolicy
            agree={state.agreedTermAndPolicy}
            onAgree={e => {
              dispatch({
                payload: {
                  agreedTermAndPolicy: e.target.checked,
                },
              });
            }}
            onContinue={() => {
              const parsedProducts = parseProducts();

              saveParsedProducts(parsedProducts);
              saveSelectedPaymentMethod(state.selectedPaymentMethod);

              paymentService
                .purchase(parsedProducts, state.selectedPaymentMethod.code)
                .then(({ data: { onepay } }) => {
                  window.open(onepay.paymentRequestUrl, '_blank');
                })
                .then(() => {
                  dispatch({
                    payload: {
                      showTermAndPolicyModal: false,
                      selectedPaymentMethod: null,
                      agreedTermAndPolicy: false,
                    },
                  });
                })
                .catch(err => {
                  if (err.response.status === 401) {
                    navigate('/login/');
                  }
                });
            }}
          />
        </Modal>
      </PricingContext.Provider>
    </MainLayout>
  );
}

const OrderDetailWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const ActionContainer = styled.div`
  padding-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PurchaseBtn = styled(LightGreenButton)`
  margin: 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const RedButton = styled(LightGreenButton)`
  background-color: ${p => p.theme.brand};
  padding-left: 5em;
  padding-right: 5em;
  text-transform: uppercase;

  &:hover {
    background-color: ${p => darken(0.1, p.theme.brand)};
  }

  @media screen and (min-width: 769px) {
    display: none;
  }
`;

export default Pricing;

Pricing.propTypes = {
  location: PropTypes.shape({}).isRequired,
};
